import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100">

      <Container>
        <div className="text-center">
          
          <h4 className="heading-h2"><span className="heading-h3 bluecolor">FAQ</span>
            Frequently Asked Questions</h4>
        </div>

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <h5 className="mb-0">
                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                Does the PancakeSwap clone script work similarly to PancakeSwap?
                </Accordion.Toggle>
                </h5>
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">The PancakeSwap clone script holds all the core features and plug-ins that are present in the existing platform - PancakeSwap. It also fills the gaps that are present in the PancakeSwap. So, the workflow of this script is the same as the PancakeSwap and also provides a better experience for your users.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
              <h5 className="mb-0">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                What is the Cost of PancakeSwap clone software?
                </Accordion.Toggle>
                </h5>
              </div>

              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">The cost of the premium white-label PancakeSwap clone script will be based on various factors such as demands, features, the blockchain you choose, and more. However, the development cost is mainly based on your business requirements and the scale of the project.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
              <h5 className="mb-0">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                Is your PancakeSwap clone script completely secure?
                </Accordion.Toggle>
                </h5>
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">Our PancakeSwap clone software has been developed by well-trained blockchain experts. Besides, our script measures up to all security standards in the industry. So our script is free from all the security threats and avoids all malware activities.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
              <h5 className="mb-0">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                What are the main highlights of the PancakeSwap clone script?
                </Accordion.Toggle>
                </h5>
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">The main highlight of our clone script is customization, so you can modify the name, theme, appearance, back-end functionality, features, and other add-on modules as per your business necessities. In addition to this, the script we offer is completely budget-friendly. Therefore, you can get the script at the best price on the market.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card mb-0' : 'panel-wrap card mb-0'}>
              <div className="panel-header card-header">
              <h5 className="mb-0">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                What are the Features of PancakeSwap clone software?
                </Accordion.Toggle>
                </h5>
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">Our clone script is built with all vital features which include AMM provisions, lottery schemes, IFO, instant token swap mechanism, performance charts, transaction history, real-time analytics, multiple crypto wallet connection support, and more
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection
import * as React from "react"

import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Banner from "../components/Pancakeswap/Banner"
import Whatis from "../components/Pancakeswap/Whatis"
//import QuickviewSection from "../components/Pancakeswap/Quickview"
import HowOur from "../components/Pancakeswap/HowOur"
import TopFeatures from "../components/Pancakeswap/TopFeatures"
import CoreFeatures from "../components/Pancakeswap/CoreFeatures"
import SecurityofOur from "../components/Pancakeswap/SecurityofOur"
import TechStack from "../components/Pancakeswap/TechStack"
import FaqSection from "../components/Pancakeswap/FaqSection"
import WhyShould from "../components/Pancakeswap/WhyShould"
import DevApproach from "../components/Pancakeswap/DevApproach"
import WhyChoose from "../components/Pancakeswap/WhyChoose"
import Addon from "../components/Pancakeswap/Addon"
import Testimonial from "../components/Pancakeswap/Testimonial"
import UserFriendly from "../components/Cryptoexchange/UserFriendly"





const Clone = () => (
  <section className="landing clone pancake">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>Pancakeswap Clone Script to Launch a DeFi Exchange</title>
        <meta name="description" content="Pancakeswap clone script is a pre-built software used to create your own decentralized exchange with similar functions of pancakeswap. Get a free demo!" />
        <meta property="keywords" content="PancakeSwap clone Software, PancakeSwap Website Clone, PancakeSwap Exchange Clone, build DeFi based decentralized exchange on binance smart chain" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Pancakeswap Clone Script to Launch a DeFi Exchange" />
        <meta property="og:description" content="Pancakeswap clone script is a pre-built software used to create your own decentralized exchange with similar functions of pancakeswap. Get a free demo!" />
        <meta property="og:url" content="https://www.coinsclone.com/pancakeswap-clone-script/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/pancakeswap-clone-script.png" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Pancakeswap clone script is a pre-built software used to create your own decentralized exchange with similar functions of pancakeswap. Get a free demo!" />
        <meta name="twitter:title" content="Pancakeswap Clone Script to Launch a DeFi Exchange" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/pancakeswap-clone-script.png" />
        <link rel="canonical" href="https://www.coinsclone.com/pancakeswap-clone-script/" />
        
      </Helmet>
      <Banner />
      <div className="breadcrumb">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | PancakeSwap Clone Script </span>
        </div>
      </div>
      <Whatis />
      {/* <QuickviewSection /> */}
      <HowOur />
      <WhyShould />
      <TopFeatures />
      <CoreFeatures />
      <SecurityofOur />
      <Addon />
      <WhyChoose />
      <DevApproach />
      <Testimonial />
      <TechStack />
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
)

export default Clone

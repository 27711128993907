import React from 'react'




const HowOur = () => {



  return (
    <section className="how-our mb-0">
      <div className="container">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

          <p className="heading-h2"><span className="heading-h3 bluecolor">How Does Our</span>
            PancakeSwap Clone Script work?</p>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="1080.258" height="1774.071" viewBox="0 0 1080.258 1774.071">
          <g id="Flow" transform="translate(-522.612 -78.929)">
            <g id="Ellipse_196" data-name="Ellipse 196" transform="translate(522.612 299.494)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
              <circle cx={108} cy={108} r={108} stroke="none" />
              <circle cx={108} cy={108} r={107} fill="none" />
            </g>
            <g id="Ellipse_196-2" data-name="Ellipse 196" transform="translate(1219.612 119.494)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
              <circle cx={108} cy={108} r={108} stroke="none" />
              <circle cx={108} cy={108} r={107} fill="none" />
            </g>
            <g id="Admin" transform="translate(1193.13 -28.721)">
              <path id="Path_4" data-name="Path 4" d="M594.859,440.238v96.675l16.171,26.354,1.231,2h63.805l1.289-125.033Z" transform="translate(-286.95 -179.439)" fill="#2f2e41" />
              <path id="Path_5" data-name="Path 5" d="M594.859,440.238v96.675l16.171,26.354.992-123.028Z" transform="translate(-286.95 -179.439)" opacity="0.1" />
              <path id="Path_8" data-name="Path 8" d="M409.74,407.787v5.8H63v-8.378l32.225-16.757H381.382Z" transform="translate(0 -151.499)" fill="#2f2e41" />
              <path id="Path_9" data-name="Path 9" d="M409.74,427.42v5.8H63v-8.378Z" transform="translate(0 -171.132)" opacity="0.1" />
              <path id="Path_14" data-name="Path 14" d="M676.129,493.736H691.6l6.445-4.511h-29Z" transform="translate(-326.971 -205.868)" opacity="0.1" />
              <path id="Path_15" data-name="Path 15" d="M676.129,534.325H691.6l6.445-4.511h-29Z" transform="translate(-326.971 -227.767)" opacity="0.1" />
              <path id="Path_16" data-name="Path 16" d="M676.129,591.71H691.6l6.445-4.511h-29Z" transform="translate(-326.971 -258.727)" opacity="0.1" />
              <path id="Path_17" data-name="Path 17" d="M676.129,649.095H691.6l6.445-4.512h-29Z" transform="translate(-326.971 -289.687)" opacity="0.1" />
              <path id="Path_18" data-name="Path 18" d="M691.709,389.1l-2.15,13.726s-10.584,5.623-3.142,5.788,42.5,0,42.5,0,6.78,0-3.969-5.953l-2.15-14.387Z" transform="translate(-412.507 -168.276)" fill="#3f3d56" />
              <path id="Path_19" data-name="Path 19" d="M684.923,408.465a29.276,29.276,0,0,1,4.639-3.071l2.15-13.726,31.089.029,2.15,13.531a28.23,28.23,0,0,1,4.968,3.269c1.38-.315,2.889-1.486-4.968-5.837l-2.15-14.387-31.089.827-2.15,13.726S680.668,407.552,684.923,408.465Z" transform="translate(-412.51 -168.276)" opacity="0.1" />
              <rect id="Rectangle_1" data-name="Rectangle 1" width="140.068" height="97.568" rx="10.695" transform="translate(224.547 125.158)" fill="#3f3d56" />
              <rect id="Rectangle_2" data-name="Rectangle 2" width="130.642" height="73.094" transform="translate(229.26 130.367)" fill="#f2f2f2" />
              <circle id="Ellipse_2" data-name="Ellipse 2" cx="0.827" cy="0.827" r="0.827" transform="translate(293.754 127.225)" fill="#f2f2f2" />
              <path id="Path_20" data-name="Path 20" d="M722.393,364.931v8.553a4.924,4.924,0,0,1-4.925,4.925H587.249a4.924,4.924,0,0,1-4.925-4.925v-8.552Z" transform="translate(-357.777 -155.682)" fill="#3f3d56" />
              <circle id="Ellipse_3" data-name="Ellipse 3" cx="3.142" cy="3.142" r="3.142" transform="translate(291.439 212.887)" fill="#f2f2f2" />
              <path id="Path_21" data-name="Path 21" d="M451.04,408.221v1.654H362.071v-1.323l.122-.331,2.193-5.953h84.835Z" transform="translate(-161.355 -158.953)" fill="#3f3d56" />
              <path id="Path_22" data-name="Path 22" d="M768.87,441.651c-.162.693-.774,1.424-2.156,2.115-4.961,2.481-15.049-.661-15.049-.661s-7.772-1.323-7.772-4.8a6.193,6.193,0,0,1,.681-.405c2.086-1.1,9-3.826,21.264.115a5.115,5.115,0,0,1,2.331,1.527,2.439,2.439,0,0,1,.7,2.1Z" transform="translate(-444.947 -194.002)" fill="#3f3d56" />
              <path id="Path_23" data-name="Path 23" d="M769.668,441.651c-6.072,2.327-11.485,2.5-17.04-1.358a12.2,12.2,0,0,0-7.256-2.39c2.086-1.1,9-3.826,21.264.115a5.114,5.114,0,0,1,2.331,1.527,2.439,2.439,0,0,1,.7,2.1Z" transform="translate(-445.745 -194.002)" opacity="0.1" />
              <ellipse id="Ellipse_4" data-name="Ellipse 4" cx="2.15" cy="0.661" rx="2.15" ry="0.661" transform="translate(314.16 244.969)" fill="#f2f2f2" />
              <path id="Path_24" data-name="Path 24" d="M451.04,415.2v1.654H362.071v-1.323l.122-.331Z" transform="translate(-161.355 -165.928)" opacity="0.1" />
              <circle id="Ellipse_5" data-name="Ellipse 5" cx="17.612" cy="17.612" r="17.612" transform="translate(198.101 112.254)" fill="#ffb9b9" />
              <path id="Path_26" data-name="Path 26" d="M534.957,208.946v27.808h28.735s-3.708-23.173-1.854-30.125Z" transform="translate(-332.222 -70.275)" fill="#ffb9b9" />
              <path id="Path_27" data-name="Path 27" d="M549.608,266.206s-27.345-13.441-27.345-14.367-2.781-6.952-4.635-6.952-17.612-5.1-26.418,2.317l-.927,6.025-33.833,22.246,9.269,33.833s6.025,6.489,4.171,11.123,1.39,28.272,1.39,28.272l72.764-2.317V314.87s2.317-7.415,3.708-9.733,0-9.269,0-9.269l11.123-13.9S557.487,268.986,549.608,266.206Z" transform="translate(-289.866 -89.994)" fill="#d0cde1" />
              <path id="Path_28" data-name="Path 28" d="M448.79,324.671l2.086-11.355s-12.05,52.372-10.66,58.4,11.587,15.294,12.977,15.758,14.367-39.858,14.367-39.858Z" transform="translate(-281.047 -127.835)" opacity="0.1" />
              <path id="Path_29" data-name="Path 29" d="M451.709,310.3l-7.879,1.39s-12.05,52.372-10.659,58.4,11.587,15.294,12.977,15.758,14.367-39.858,14.367-39.858Z" transform="translate(-277.246 -126.206)" fill="#d0cde1" />
              <path id="Path_30" data-name="Path 30" d="M649.358,335.054l3.939-7.647s5.562,52.372,1.854,57.47-18.374,7.006-18.374,7.006l-.164-38.058Z" transform="translate(-387.067 -135.437)" opacity="0.1" />
              <path id="Path_31" data-name="Path 31" d="M653.312,314.323l6.025,6.025s5.562,52.372,1.854,57.47-18.374,7.006-18.374,7.006l-.164-38.058Z" transform="translate(-390.325 -128.378)" fill="#d0cde1" />
              <path id="Path_32" data-name="Path 32" d="M451.579,485.875s-13.441-5.1-15.758,4.635,5.562,53.3,5.562,53.3,5.1,32.906,8.342,33.833,17.612,1.39,19.929-2.781-6.952-46.81-6.952-46.81l53.762-.927s-3.244,37.077-5.1,40.322-1.854,14.367,0,14.367,22.246-.927,23.637-3.708,9.269-65.349,9.269-65.349,6.489-21.783-.927-20.393S451.579,485.875,451.579,485.875Z" transform="translate(-278.506 -220.214)" fill="#3f3d56" />
              <path id="Path_33" data-name="Path 33" d="M621.474,682.016s4.635-3.244,6.952,0,3.244,6.952-6.952,12.514S603.4,698.7,603.4,697.774v-13.44Z" transform="translate(-369.148 -325.978)" fill="#2f2e41" />
              <path id="Path_34" data-name="Path 34" d="M458.616,679s-4.635-3.244-6.952,0-3.244,6.952,6.952,12.514,18.075,4.171,18.075,3.244V681.314Z" transform="translate(-286.469 -324.35)" fill="#2f2e41" />
              <path id="Path_35" data-name="Path 35" d="M539.924,144.3a14.9,14.9,0,0,0-5.173.737,20,20,0,0,0-3.685,1.95l-7.776,4.835a3.641,3.641,0,0,0-2.195,3.394l-.972,7.276c-.245,1.836-.471,3.8.351,5.46.686,1.386,1.995,2.334,3.07,3.445a12.643,12.643,0,0,1,3.332,6.475c.556,3.038.2,6.7,2.6,8.635a8.169,8.169,0,0,0,4.746,1.346,70.814,70.814,0,0,0,13.677-.526,5.442,5.442,0,0,0,2.066-.579,5.675,5.675,0,0,0,1.813-2.156c1.736-2.956,3.526-6.1,3.507-9.529-.009-1.7-.443-3.518.371-5.005a19.571,19.571,0,0,1,1.4-1.817,10.034,10.034,0,0,0,1.369-4.968c.525-5.53-.024-9.738-4.1-13.633C550.525,146,545.112,144.335,539.924,144.3Z" transform="translate(-324.097 -36.643)" fill="#2f2e41" />
              <path id="Path_36" data-name="Path 36" d="M282.814,569.7l-15.468,78.629h7.734l14.824-77.34Z" transform="translate(-110.249 -249.288)" fill="#2f2e41" />
              <path id="Path_37" data-name="Path 37" d="M408.708,580.679l9.668,90.23h6.445l-5.8-92.808Z" transform="translate(-186.517 -253.819)" fill="#2f2e41" />
              <path id="Path_38" data-name="Path 38" d="M466.093,576.591l13.534,59.939h6.445L473.183,575.3Z" transform="translate(-217.478 -252.309)" fill="#2f2e41" />
              <path id="Path_39" data-name="Path 39" d="M351.858,578.1l-1.934,47.693h5.156l3.867-47.693Z" transform="translate(-154.802 -253.819)" fill="#2f2e41" />
              <path id="Path_40" data-name="Path 40" d="M443.556,504.08s-10.312-68.961,18.69-70.895,70.25,0,70.25,0,23.846-1.934,16.757,70.895c0,0,6.445,21.268-51.56,19.335S443.556,504.08,443.556,504.08Z" transform="translate(-281.948 -192.043)" fill="#6c63ff" />
            </g>
            <g id="Flow-2" data-name="Flow" transform="translate(10.25 -4052.926)">
              <g id="Line" transform="translate(0 -417)">
                <line id="Line_152" data-name="Line 152" y2="619.926" transform="translate(719.793 4998.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_184" data-name="Line 184" y1={427} transform="translate(1276.646 4928.426)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_194" data-name="Line 194" x2="386.25" transform="translate(890 5206.074)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
                <line id="Line_194-2" data-name="Line 194" x2="190.25" transform="translate(848 5381.426)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
                <line id="Line_194-3" data-name="Line 194" y1={265} transform="translate(1038.25 5381.426)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
                <line id="Line_194-4" data-name="Line 194" x2={112} transform="translate(1038.25 5646.426)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
                <g id="Group_2" data-name="Group 2" transform="translate(1406.25 5381.426)">
                  <line id="Line_197" data-name="Line 197" x1={42} fill="none" stroke="#5c5c5c" strokeWidth={2} opacity="0.2" />
                  <line id="Line_198" data-name="Line 198" x1={42} transform="translate(0 97)" fill="none" stroke="#5c5c5c" strokeWidth={2} opacity="0.2" />
                  <line id="Line_199" data-name="Line 199" y1={97} transform="translate(42)" fill="none" stroke="#5c5c5c" strokeWidth={2} opacity="0.2" />
                  <line id="Line_200" data-name="Line 200" x2="81.25" transform="translate(42 49)" fill="none" stroke="#5c5c5c" strokeWidth={2} opacity="0.2" />
                  <line id="Line_200-2" data-name="Line 200" y1={314} transform="translate(123.25 49)" fill="none" stroke="#5c5c5c" strokeWidth={2} opacity="0.2" />
                  <line id="Line_200-3" data-name="Line 200" x1="449.25" transform="translate(-326 363)" fill="none" stroke="#5c5c5c" strokeWidth={2} opacity="0.2" />
                </g>
              </g>
              <g id="Admin-2" data-name="Admin" transform="translate(-164.776 -7420.812)">
                <rect id="Rectangle_298" data-name="Rectangle 298" width={179} height={43} rx="21.5" transform="translate(1307.776 11718.812)" />
                <text id="Admin_Dashboard" data-name="Admin Dashboard" transform="translate(1336.211 11744.812)" fill="#fff" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x={0} y={0}>Admin Dashboard</tspan></text>
              </g>
              <g id="User" transform="translate(-707.5 -7214.283)">
                <rect id="Rectangle_298-2" data-name="Rectangle 298" width={141} height={43} rx="21.5" transform="translate(1330 11719)" />
                <text id="User-2" data-name="User" transform="translate(1385.586 11745)" fill="#fff" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x={0} y={0}>User</tspan></text>
              </g>
              <text id="User_Can_be_Login_or_Register" data-name="User Can be Login  or Register" transform="translate(740.25 4665.926)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500} letterSpacing="0.03em"><tspan x={0} y={0}>Connect </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Web 3</tspan><tspan fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x={0} y={20}>Wallet</tspan></tspan></text>
              <g id="KYC" transform="translate(17.611 4142.5)">
                <g id="Rectangle_45" data-name="Rectangle 45" transform="translate(571.139 785)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={255} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
                </g>
                <text id="Completes_KYC_Security" data-name="Completes KYC/Security" transform="translate(618.139 821)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x={0} y={0}>Choose the </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>pair to swap</tspan></text>
              </g>
              <g id="Rectangle_45-2" data-name="Rectangle 45" transform="translate(558.75 4753.926)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={323} height={68} rx={25} stroke="none" />
                <rect x={1} y={1} width={321} height={66} rx={24} fill="none" />
              </g>
              <text id="Two-Factor_Authentication_2FA_Mail_SMS" data-name="Two-Factor Authentication  2FA Mail/SMS" transform="translate(603.75 4792.926)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500} letterSpacing="0.03em"><tspan x={0} y={0}>View Swap or </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Exchange Pairs</tspan></text>
              <text id="Two-Factor_Authentication_2FA_Reset" data-name="Two-Factor Authentication  2FA Reset" transform="translate(1311 4780.926)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500} letterSpacing="0.03em"><tspan x={0} y={0}>Pair</tspan><tspan fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x={0} y={22}>Management</tspan></tspan></text>
              <text id="Two-Factor_Authentication_2FA_Reset-2" data-name="Two-Factor Authentication  2FA Reset" transform="translate(1086.75 5307.927)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700} letterSpacing="0.03em"><tspan x={0} y={0}>Earn</tspan></text>
              <g id="Complete_Trade" data-name="Complete Trade" transform="translate(572 4393.43)">
                <g id="Rectangle_45-3" data-name="Rectangle 45" transform="translate(577.75 542.497)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={255} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
                </g>
                <text id="Complete_Trade-2" data-name="Complete Trade" transform="translate(611.75 578.497)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x={0} y={0}>Add or Delete </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Farming Pairs</tspan></text>
              </g>
              <g id="Complete_Trade-3" data-name="Complete Trade" transform="translate(572 4705.429)">
                <g id="Rectangle_45-4" data-name="Rectangle 45" transform="translate(577.75 322.497)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={255} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
                </g>
                <text id="Complete_Trade-4" data-name="Complete Trade" transform="translate(639.75 358.497)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x={0} y={0}>Add or Delete </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Pools</tspan></text>
              </g>
              <g id="Complete_Trade-5" data-name="Complete Trade" transform="translate(572 4878.429)">
                <g id="Rectangle_45-5" data-name="Rectangle 45" transform="translate(577.75 322.497)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={255} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
                </g>
                <text id="Complete_Trade-6" data-name="Complete Trade" transform="translate(661.75 358.497)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x={0} y={0}>Swap </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>History</tspan></text>
              </g>
              <g id="User_Dashboard" data-name="User Dashboard" transform="translate(21.543 4419.429)">
                <g id="Rectangle_45-6" data-name="Rectangle 45" transform="translate(491.207 784.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={414} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={412} height={60} rx={24} fill="none" />
                </g>
                <text id="User_Dashboard-2" data-name="User Dashboard" transform="translate(516.207 820.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x={0} y={0}>Swap Completion or Asset Received to the </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>web 3 wallet</tspan></text>
              </g>
              <g id="My_Profile" data-name="My Profile" transform="translate(22 4292.429)">
                <g id="Rectangle_45-7" data-name="Rectangle 45" transform="translate(570.75 784.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={255} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
                </g>
                <text id="My_Profile-2" data-name="My Profile" transform="translate(607.75 820.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x={0} y={0}>Slippage Tolerance </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Settings</tspan></text>
              </g>
              <g id="Dot">
                <circle id="Ellipse_134" data-name="Ellipse 134" cx="7.5" cy="7.5" r="7.5" transform="translate(712 4571)" fill="#4b9aff" />
                <circle id="Ellipse_134-2" data-name="Ellipse 134" cx="7.5" cy="7.5" r="7.5" transform="translate(712 4663.926)" fill="#4b9aff" />
                <circle id="Ellipse_1367" data-name="Ellipse 1367" cx="7.5" cy="7.5" r="7.5" transform="translate(1269 4780)" fill="#ff6464" />
              </g>
              <g id="Group_3" data-name="Group 3" transform="translate(0 -266.283)">
                <line id="Line_183" data-name="Line 183" y1="131.926" transform="translate(1194.646 4635.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <g id="Rectangle_45-8" data-name="Rectangle 45" transform="translate(1142.75 4709.209)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={255} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
                </g>
                <text id="User_Balance" data-name="User Balance" transform="translate(1197.75 4744.209)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x={0} y={0}>Manage User </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Address</tspan></text>
                <circle id="Ellipse_1368" data-name="Ellipse 1368" cx="7.5" cy="7.5" r="7.5" transform="translate(1186 4624)" fill="#ff6464" />
              </g>
              <g id="Group_1" data-name="Group 1" transform="translate(599.646 28.926)">
                <g id="Group_4" data-name="Group 4">
                  <line id="Line_176" data-name="Line 176" y2="136.926" transform="translate(532.646 5299.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                  <line id="Line_176-2" data-name="Line 176" y2="103.926" transform="translate(572.646 5538.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                  <line id="Line_176-3" data-name="Line 176" y2="103.926" transform="translate(973.427 5436.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                  <line id="Line_176-4" data-name="Line 176" y2="103.926" transform="translate(950.5 5642.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                  <line id="Line_176-5" data-name="Line 176" y2={99} transform="translate(602.646 5746.426)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                  <line id="Line_178" data-name="Line 178" x1="45.854" transform="translate(602.646 5845.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                  <line id="Line_179" data-name="Line 179" x1="45.854" transform="translate(602.646 5744.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                  <line id="Line_179-2" data-name="Line 179" x1="45.854" transform="translate(904.646 5744.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                  <line id="Line_180" data-name="Line 180" x1="76.25" transform="translate(572.25 5642.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                  <line id="Line_180-2" data-name="Line 180" x1="45.854" transform="translate(904.646 5642.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                  <line id="Line_181" data-name="Line 181" x1="45.854" transform="translate(572.646 5538.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                  <line id="Line_181-2" data-name="Line 181" x1="45.854" transform="translate(927.573 5538.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                  <line id="Line_182" data-name="Line 182" x1="116.25" transform="translate(532.25 5436.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                  <line id="Line_182-2" data-name="Line 182" x1="68.604" transform="translate(904.646 5436.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                </g>
                <g id="Rectangle_45-9" data-name="Rectangle 45" transform="translate(648.103 5407)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={255} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
                </g>
                <text id="Price_Chart" data-name="Price Chart" transform="translate(740.103 5443)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x={0} y={0}>Syrup </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Pool</tspan></text>
                <g id="Rectangle_45-10" data-name="Rectangle 45" transform="translate(620.104 5509)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={311} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={309} height={60} rx={24} fill="none" />
                </g>
                <text id="Wallet_Balance_Deposit_Withdraw" data-name="Wallet Balance Deposit/Withdraw" transform="translate(741.104 5545)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x={0} y={0}>Find </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Pools</tspan></text>
                <g id="Rectangle_45-11" data-name="Rectangle 45" transform="translate(648.104 5611)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={255} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
                </g>
                <text id="View_Ads_Buy_Sell" data-name="View Ads Buy/Sell" transform="translate(725.104 5647)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x={0} y={0}>Connect </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Wallet</tspan></text>
                <g id="Rectangle_45-12" data-name="Rectangle 45" transform="translate(633.104 5713)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={285} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={283} height={60} rx={24} fill="none" />
                </g>
                <text id="Post_Ads_Buy_Sell" data-name="Post Ads Buy/Sell" transform="translate(722.104 5749)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x={0} y={0}>Stake LP </tspan><tspan y={0} xmlSpace="preserve" fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}> Tokens</tspan></text>
                <g id="Rectangle_45-13" data-name="Rectangle 45" transform="translate(648.104 5815)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={255} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
                </g>
                <text id="User_To_User_Message" data-name="User To User  Message" transform="translate(677.104 5851)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x={0} y={0}>Earn or Harvest Desired</tspan><tspan y={0} xmlSpace="preserve" fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}> Token</tspan></text>
                <circle id="Ellipse_1364" data-name="Ellipse 1364" cx="7.5" cy="7.5" r="7.5" transform="translate(525 5292)" fill="#ff6464" />
              </g>
              <g id="Group_6" data-name="Group 6" transform="translate(63.75 28.926)">
                <g id="Group_4-2" data-name="Group 4" transform="translate(574 5299.5)">
                  <line id="Line_176-6" data-name="Line 176" y2="136.926" transform="translate(440.781)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                  <line id="Line_176-7" data-name="Line 176" y2="103.926" transform="translate(400.781 239)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                  <line id="Line_176-8" data-name="Line 176" y2="103.926" transform="translate(0 137)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                  <line id="Line_176-9" data-name="Line 176" y2="103.926" transform="translate(22.927 343)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                  <line id="Line_176-10" data-name="Line 176" y2={99} transform="translate(370.781 446.926)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                  <line id="Line_178-2" data-name="Line 178" x2="45.854" transform="translate(324.927 546)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                  <line id="Line_179-3" data-name="Line 179" x2="45.854" transform="translate(324.927 445)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                  <line id="Line_179-4" data-name="Line 179" x2="45.854" transform="translate(22.927 445)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                  <line id="Line_180-3" data-name="Line 180" x2="76.25" transform="translate(324.927 343)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                  <line id="Line_180-4" data-name="Line 180" x2="45.854" transform="translate(22.927 343)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                  <line id="Line_181-3" data-name="Line 181" x2="45.854" transform="translate(354.927 239)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                  <line id="Line_181-4" data-name="Line 181" x2="45.854" transform="translate(0 239)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                  <line id="Line_182-3" data-name="Line 182" x2="116.25" transform="translate(324.927 137)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                  <line id="Line_182-4" data-name="Line 182" x2="68.604" transform="translate(0.177 137)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                </g>
                <g id="Rectangle_45-14" data-name="Rectangle 45" transform="translate(648 5407)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={255} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
                </g>
                <text id="Price_Chart-2" data-name="Price Chart" transform="translate(729 5443)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x={0} y={0}>Yield </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Farming</tspan></text>
                <g id="Rectangle_45-15" data-name="Rectangle 45" transform="translate(620 5509)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={311} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={309} height={60} rx={24} fill="none" />
                </g>
                <text id="Wallet_Balance_Deposit_Withdraw-2" data-name="Wallet Balance Deposit/Withdraw" transform="translate(742 5545)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x={0} y={0}>Find </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Farm</tspan></text>
                <g id="Rectangle_45-16" data-name="Rectangle 45" transform="translate(648 5611)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={255} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
                </g>
                <text id="View_Ads_Buy_Sell-2" data-name="View Ads Buy/Sell" transform="translate(665 5647)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x={0} y={0}>Provide Liquidity to get </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>LP Tokens</tspan></text>
                <g id="Rectangle_45-17" data-name="Rectangle 45" transform="translate(633 5713)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={285} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={283} height={60} rx={24} fill="none" />
                </g>
                <text id="Post_Ads_Buy_Sell-2" data-name="Post Ads Buy/Sell" transform="translate(690 5749)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x={0} y={0}>Enable or Stake </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>LP Tokens</tspan></text>
                <g id="Rectangle_45-18" data-name="Rectangle 45" transform="translate(648 5815)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={255} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
                </g>
                <text id="User_To_User_Message-2" data-name="User To User  Message" transform="translate(704 5851)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x={0} y={0}>Harvest your</tspan><tspan y={0} xmlSpace="preserve" fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}> Rewards</tspan></text>
                <circle id="Ellipse_1364-2" data-name="Ellipse 1364" cx="7.5" cy="7.5" r="7.5" transform="translate(1008 5292)" fill="#ff6464" />
              </g>
            </g>
            <g id="User-3" data-name="User" transform="translate(103.102 234.665)">
              <path id="Path_43" data-name="Path 43" d="M814.148,527.116c-19.925.843-31.192.23-45.717-12.378-9.889-.263-17.75-7.415-17.523-15.942s8.457-15.251,18.346-14.988l35.907,1.7c9.889.263,28.28-.345,28.053,8.182-.111,4.187-.113,9.616-.5,15.3a19.436,19.436,0,0,1-18.564,18.12Z" transform="translate(-247.822 -305.505)" fill="#2f2e41" />
              <path id="Path_44" data-name="Path 44" d="M509.9,487.557l-10.288,1.681-11.379-38.881,15.184-2.481Z" transform="translate(-7.571 -186.122)" fill="#ffb6b6" />
              <path id="Path_45" data-name="Path 45" d="M722.9,529.065c19.181,4.716,33.344-.6,45.01.094,6.631-11.189,27.8-25.4,16.249-31.385-6.432-3.332-71.816-11.012-80.4-2.148-22.071,22.793,5.332,94.776,5.332,94.776l15.242-.2,4.87-6.951-3.922-7.575,3.71-6.193-4.246-6.38Z" transform="translate(-224.657 -308.705)" fill="#2f2e41" />
              <path id="Path_46" data-name="Path 46" d="M737.446,692l-6.74,1.8-2.907-6.041-1.377,7.188-17.876,4.786a4.16,4.16,0,0,1-4.245-6.714l11.635-13.681-1.723-6.433,15.255-3.124Z" transform="translate(-228.044 -382.792)" fill="#2f2e41" />
              <path id="Path_47" data-name="Path 47" d="M641.847,473.5l-7.508,7.231-31.465-25.518,11.081-10.673Z" transform="translate(-55.212 -184.736)" fill="#ffb6b6" />
              <path id="Path_48" data-name="Path 48" d="M765.407,507.073c17.583,6.772,32.961-.759,48.153-9.49-.906-12.975-1.412-31.684-14.314-30.04-7.185.916-66.573,10.729-68.6,22.9-5.2,31.3,69.338,90.961,69.338,90.961l12.427-8.827.055-8.486-7.533-4-.468-7.2-7.12-2.835Z" transform="translate(-239.297 -298.707)" fill="#2f2e41" />
              <path id="Path_49" data-name="Path 49" d="M890.077,667.1l-4.519,5.316-5.826-3.318,2.953,6.7-11.985,14.1a4.16,4.16,0,0,1-7.309-3.11l1.794-17.87-5.074-4.313,10.774-11.243Z" transform="translate(-293.205 -375.967)" fill="#2f2e41" />
              <path id="Path_50" data-name="Path 50" d="M766.44,402.836c-1.239,0-1.942-.243-2.214-.764-.4-.764-2.952-1.763-2.149-2.92.56-.806,1.912-1.886,1.392-2.507-2.005-2.394-7.011-71.069-8.19-80.438a18.667,18.667,0,0,1,1.147-8.548c3.3-9.363,7.815-12.24,16.8-17.972q1.094-.7,2.279-1.458c1.022-.656.965.877.9-1.428-.067-2.562-.136-5.211,1.119-6.867l.082-.108.132-.031c3.035-.7,13.245-2,19.291.009l.083.028.062.062c1.436,1.436,1.567,4.218,1.694,6.908.1,2.209.2.584,1.014,1.49,3.106,3.462,7.786,5.463,11.689,6.6a11.438,11.438,0,0,1,8.274,11.361c-.423,12.817-.091,33.084-2.278,47.366-3.641,23.77,29.6,58.1,10.56,58.8a.487.487,0,0,1,.015.5C826.789,415.465,771.109,402.836,766.44,402.836ZM827.507,412.83Z" transform="translate(-249.601 -220.285)" fill="#e6e6e6" />
              <circle id="Ellipse_6" data-name="Ellipse 6" cx="22.695" cy="22.695" r="22.695" transform="translate(513.413 12.213)" fill="#2f2e41" />
              <circle id="Ellipse_7" data-name="Ellipse 7" cx="16.31" cy="16.31" r="16.31" transform="matrix(1, -0.028, 0.028, 1, 520.708, 27.333)" fill="#ffb6b6" />
              <path id="Path_51" data-name="Path 51" d="M794.454,210.036l-6.612.187a15.737,15.737,0,0,0-15.273,16.158l0,.146.12.076a20.866,20.866,0,0,0,12.142,3.455l.372-.011,3.653-.166a10.094,10.094,0,0,0,.6-4.977,33.576,33.576,0,0,1,1.288,4.889l16.391-.746a3.661,3.661,0,0,0,3.479-3.745,15.737,15.737,0,0,0-16.163-15.267Z" transform="translate(-256.823 -191.736)" fill="#2f2e41" />
              <path id="Path_52" data-name="Path 52" d="M748.764,192.932c-1.492,1.84-3.888,2.607-6.041,3.6a25.834,25.834,0,0,0-14.468,19.291c-.507,3.379-.33,6.857-1.189,10.162s-3.1,6.586-6.451,7.251c-2.264.453-4.57-.361-6.734-1.167q-1.786-.66-3.571-1.325,1.711-4.673,3.416-9.346a46.853,46.853,0,0,1-6.594,8.173q-6.4-2.372-12.81-4.743c10.4-.255,8.337-10.3,10.376-20.488,1.121-5.6,11.9-9.676,13.884-15.03A16.2,16.2,0,0,1,747.914,187C750.073,187.795,750.229,191.115,748.764,192.932Z" transform="translate(-224.308 -178.725)" fill="#2f2e41" />
              <circle id="Ellipse_8" data-name="Ellipse 8" cx="8.451" cy="8.451" r="8.451" transform="translate(517.044 4.055)" fill="#2f2e41" />
              <path id="Path_53" data-name="Path 53" d="M809.294,386.24H770.279a5.045,5.045,0,0,1-5.039-5.039V339.264a5.045,5.045,0,0,1,5.039-5.039h39.015a5.045,5.045,0,0,1,5.039,5.039V381.2a5.045,5.045,0,0,1-5.039,5.039Z" transform="translate(-253.779 -243.347)" fill="#3f3d56" />
              <circle id="Ellipse_9" data-name="Ellipse 9" cx="2.922" cy="2.922" r="2.922" transform="translate(514.675 94.093)" fill="#e6e6e6" />
              <circle id="Ellipse_10" data-name="Ellipse 10" cx="1.753" cy="1.753" r="1.753" transform="translate(515.844 95.262)" fill="#3f3d56" />
              <path id="Path_54" data-name="Path 54" d="M774.691,414.909l-26.8-6.8L743.728,421.9s28.831,2.135,29.908,2.145a6.9,6.9,0,1,0,1.055-9.138Z" transform="translate(-244.84 -274.052)" fill="#ffb6b6" />
              <path id="Path_55" data-name="Path 55" d="M738.165,312.754c-19.108,10.21-27.481,32.972-39.2,52.976a15.805,15.805,0,0,0,15.43,17.543l22.933.528-2.875-14.151-7.671-4.358,9.067-21.934Z" transform="translate(-226.196 -234.424)" fill="#e6e6e6" />
              <path id="Path_56" data-name="Path 56" d="M805.044,344.041l18.29,22.195L815.781,378.2s-18.39-28.751-18.805-29.745a6.9,6.9,0,1,1,8.068-4.418Z" transform="translate(-264.753 -243.605)" fill="#ffb6b6" />
              <path id="Path_57" data-name="Path 57" d="M843.144,313.624c5.52-3.051,21.623,44,4.679,70.757a15.805,15.805,0,0,1-22.061-7.691l-8.771-21.467,14.054-4.48,6.712,7.7-.934-15.323,7.988-21.777Z" transform="translate(-275.285 -234.727)" fill="#e6e6e6" />
            </g>
          </g>
        </svg>
      </div>
    </section>
  )
}

export default HowOur